<div style="padding:16px" xmlns="http://www.w3.org/1999/html">
  <div style="font-size: 24px; font-weight: bold; padding-bottom: 32px">Willst du den Tenant wirklich löschen ?</div>
    <div>Zum löschen "DELETE" eingeben:</div>
    <mat-form-field color="accent" [formGroup]="deleteFormGroup">
      <mat-label>Confirm Delete</mat-label>
      <input matInput formControlName="delete"  />
    </mat-form-field>
  <div style="display:flex; flex-direction: row; justify-content: end">
  <button style="margin-right: 16px" mat-button color="accent" (click)="abort()">Abbrechen</button>
  <button mat-raised-button color="accent" (click)="delete()">Löschen</button>
  </div>
</div>
