export default class Country {
    constructor(
        public id: string,
        public name: string,
        public code: string,
    ) {
    }

    static fromJson(json: any) {
        return new Country(
            json?.id,
            json?.name,
            json?.code,
        )
    }
}
