import {Component, Inject} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'flavoro-dialog',
  templateUrl: './flavoro-dialog.component.html',
  styleUrls: ['./flavoro-dialog.component.scss']
})
export class FlavoroDialogComponent{
  tenantFormGroup: FormGroup
  tenantAddressFormGroup: FormGroup
  tenantCountryFormGroup: FormGroup
  restaurantFormGroup: FormGroup
  restaurantAddressFormGroup: FormGroup
  restaurantCountryFormGroup: FormGroup
  tenant: boolean
  addTenant: boolean = false;
  addRestaurant: boolean = false;
  constructor(public dialogRef: MatDialogRef<FlavoroDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any, private snackbar: MatSnackBar) {
    this.tenantFormGroup = data.tenantFormGroup;
    this.tenantAddressFormGroup = this.tenantFormGroup?.controls["address"] as FormGroup;
    this.tenantCountryFormGroup = this.tenantAddressFormGroup?.controls["country"] as FormGroup;
    this.restaurantFormGroup = data.restaurantFormGroup;
    this.restaurantAddressFormGroup = this.restaurantFormGroup?.controls["address"] as FormGroup;
    this.restaurantCountryFormGroup = this.restaurantAddressFormGroup?.controls["country"] as FormGroup;
    this.tenant = data.tenant;
    this.addTenant = data.addTenant;
    this.addRestaurant = data.addRestaurant;
  }

 save(tenant: boolean){
    let formGroup: FormGroup;
    if(tenant){
      if(!this.addTenant){
        this.tenantFormGroup.get('password')?.setErrors(null);
        this.tenantFormGroup.get('confirmPassword')?.setErrors(null);
      }
      formGroup = this.tenantFormGroup;
      this.tenantFormGroup.markAllAsTouched();
    }else{
      formGroup = this.restaurantFormGroup;
      this.restaurantFormGroup.markAllAsTouched();
    }
    console.log(formGroup)

   if(formGroup.invalid){
     this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK)
     return
   }
    this.dialogRef.close(this.tenantFormGroup ?? this.restaurantFormGroup);
  }

  protected readonly ValidationMessages = ValidationMessages;
}
