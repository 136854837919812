import {NgModule} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, NgFor} from "@angular/common";
import {MatSelectModule} from '@angular/material/select';
import {DefaultCardComponent} from "./default-card.component";

@NgModule({
    declarations: [
        DefaultCardComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        FormsModule,
        NgFor
    ],
    exports: [
        DefaultCardComponent
    ]
})
export class DefaultCardModule {
}
