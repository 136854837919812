import {Component} from "@angular/core";



@Component(
    {
        selector: 'default-card',
        templateUrl: './default-card.component.html',
        styleUrls: ['./default-card.component.scss']
    }
)
export class DefaultCardComponent{

}