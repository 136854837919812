import {FlavoroHttpClient} from "../http/http.client";
import {map, Observable} from "rxjs";
import {appendPath} from "../util/path.helper";
import {AbstractDatasource} from "./datasource.abstract";

export abstract class READDatasource<T> extends AbstractDatasource<T>{

    getById(id: string): Observable<T> {
        return this.http.get<T>(appendPath(this.path, id)).pipe(map(this.serializer));
    }

    getAll(): Observable<T[]> {
        return this.http.get<T[]>(this.path).pipe(map((json: any) => json.map(this.serializer)));
    }
}
