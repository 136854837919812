import {RestaurantsDatasource} from "./flav-board.datasource";
import Country from "../country/country.types";

export class TenantsRestaurants{
  constructor(
    public tenant: Tenant,
    public restaurants: Restaurant[]
  ) {
  }

  public static fromJson(json:any){
    return new TenantsRestaurants(
     Tenant.fromJson(json.tenant),
      json.restaurants.map((res: Restaurant) => Restaurant.fromJson(res))
    )
  }
}




export class Tenant{
  constructor(
  public id: string,
  public firstName: string,
  public lastName: string,
  public email: string,
  public phoneNumber: string,
  public password: string,
  public confirmPassword: string,
  public hasPayed: boolean,
  public validTo: string,
  public address: Address,
  ){}

  public static fromJson(json:any){
    return new Tenant(
      json.id,
      json.firstName,
      json.lastName,
      json.email,
      json.phoneNumber,
      json.password,
      json.confirmPassword,
      json.hasPayed,
      json.validTo,
      Address.fromJson(json.address)
    )
  }
}
export class Restaurant{
  constructor(
    public id: string,
    public name: string,
    public contactNumber: string,
    public mail: string,
    public tenantId: string,
    public address: Address,
  ) {}

  public static fromJson(json:any){
    return new Restaurant(
      json.id,
      json.name,
      json.contactNumber,
      json.mail,
      json.tenantId,
      Address.fromJson(json.address),
    )
  }
}
export class Address{
  constructor(
    public id: string,
    public street: string,
    public houseNumber: number,
    public city: string,
    public postalCode: number,
    public country: Country,
  ) {}
  public static fromJson(json:any){
    return new Address(
      json?.id,
      json?.street,
      json?.houseNumber,
      json?.city,
      json?.postalCode,
      Country?.fromJson(json?.country)
    )
  }
}

export class Paid{
  constructor(public tenantId: string, public validTo: Date) {
  }

  public static fromJson(json:any){
   return new Paid(
     json.tenantId,
     json.validTo
   )
  }
}

