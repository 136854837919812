<div [formGroup]="passwordFormGroup"  class="password-wrapper" [ngStyle]="{width : fieldWidth}">
    <mat-form-field (click)="confirmPassword.markAsTouched()" style="margin-bottom: 22px" color="accent">
        <mat-label>
            Passwort
        </mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field (click)="password.markAsTouched()" color="accent">
        <mat-label>
            Passwort wiederholen
        </mat-label>
        <input matInput [type]="hideConfirm ? 'password' : 'text'" formControlName="confirmPassword">
        <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
            <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error>{{getPassErrorMessage()}}</mat-error>
    </mat-form-field>
</div>