import {NgModule} from "@angular/core";
import {FlavFabButtonComponent} from "./flav-fab-button.component";
import {MatIconModule} from "@angular/material/icon";
import {MatCommonModule, MatRippleModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {NgStyle} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";

@NgModule({
    declarations: [
        FlavFabButtonComponent
    ],
    imports: [
        MatIconModule,
        MatRippleModule,
        MatButtonModule,
        NgStyle
    ],
    exports: [
        FlavFabButtonComponent
    ],
    providers: []
})
export class FlavFabButtonModule {
}
