<div class="wrapper">
  <ng-container *ngIf="tenant; else restaurant">
    <div class="title">{{tenantFormGroup.get('firstName')?.getRawValue()}} {{tenantFormGroup.get('lastName')?.getRawValue()}}</div>
    <div style="max-width: 440px" [formGroup]="tenantFormGroup">
      <div style="display: flex; flex-direction: column;">
        <div>
          <mat-form-field color="accent">
            <mat-label>
              Vorname
            </mat-label>
            <input matInput formControlName="firstName">
            <mat-error>{{ValidationMessages.REQUIRED}}</mat-error>
          </mat-form-field>
          <mat-form-field style="padding-left: 16px" color="accent">
            <mat-label>
              Nachname
            </mat-label>
            <input matInput formControlName="lastName">
            <mat-error>{{ValidationMessages.REQUIRED}}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <div class="sub-title">Kontakt</div>
          <mat-form-field color="accent">
            <mat-label>
              Email
            </mat-label>
            <input matInput formControlName="email">
            <mat-error>{{ValidationMessages.WRONG_EMAIL_SHORT}}</mat-error>
          </mat-form-field>
          <mat-form-field style="padding-left: 16px" color="accent">
            <mat-label>
              Telefon
            </mat-label>
            <input matInput formControlName="phoneNumber">
            <mat-error
              *ngIf="tenantFormGroup.get('phoneNumber')?.hasError('required')">{{ValidationMessages.REQUIRED}}</mat-error>
            <mat-error
              *ngIf="tenantFormGroup.get('phoneNumber')?.hasError('pattern')">{{ValidationMessages.WRONG_PHONE}}</mat-error>
          </mat-form-field>
        </div>
        <flav-address [compact]="true" [formGroup]="tenantAddressFormGroup"></flav-address>
        <div *ngIf="addTenant">
        <div class="sub-title">Passwort</div>
        <flav-password-field [passwordFormGroup]="tenantFormGroup"
                             fieldWidth="440px"></flav-password-field>
        </div>
      </div>
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: end; padding-top: 40px">
        <button mat-raised-button color="accent" (click)="save(true)">Speichern</button>
      </div>
    </div>
  </ng-container>
  <div style="display: flex; flex-direction: row; justify-content: end">
  </div>
</div>
<ng-template #restaurant>
  <div class="title">{{restaurantFormGroup.get('name')?.getRawValue()}}</div>
  <div  style="max-width: 440px" [formGroup]="restaurantFormGroup">
    <mat-form-field style="width: 100%" color="accent">
      <mat-label>Restaurant Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <div>
      <div class="sub-title">Kontakt</div>
      <mat-form-field color="accent">
        <mat-label>
          Email
        </mat-label>
        <input matInput formControlName="mail">
        <mat-error>{{ValidationMessages.WRONG_EMAIL_SHORT}}</mat-error>
      </mat-form-field>
      <mat-form-field style="padding-left: 16px" color="accent">
        <mat-label>
          Telefon
        </mat-label>
        <input matInput formControlName="contactNumber">
        <mat-error
          *ngIf="restaurantFormGroup.get('contactNumber')?.hasError('required')">{{ValidationMessages.REQUIRED}}</mat-error>
        <mat-error
          *ngIf="restaurantFormGroup.get('contactNumber')?.hasError('pattern')">{{ValidationMessages.WRONG_PHONE}}</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field style="width: 100%" *ngIf="addRestaurant" color="accent">
      <mat-label>
        SubDomain
      </mat-label>
      <input matInput formControlName="subdomain">
    </mat-form-field>
      <flav-address [compact]="true" [formGroup]="restaurantAddressFormGroup"></flav-address>
    <div style="width: 100%; display: flex; flex-direction: row; justify-content: end; padding-top: 40px">
      <button mat-raised-button color="accent" (click)="save(false)">Speichern</button>
    </div>
  </div>
</ng-template>




