import {Component, Input, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
import {confirmPasswordValidator} from "../../../shared/Validation/validation.password";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component(
    {
        selector: 'flav-password-field',
        templateUrl: './flav-password-field.component.html',
        styleUrls: ['./flav-password-field.component.scss'],
        providers: [
            {
                provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
                useValue: {
                    subscriptSizing: 'dynamic'
                }
            }
        ]
    }
)
export class FlavPasswordFieldComponent implements OnInit{
    @Input() passwordFormGroup!: FormGroup ;
    @Input() fieldWidth?: string;
    hide: boolean = true;
    hideConfirm: boolean = true;
    password!: FormControl;
    confirmPassword!: FormControl;
    regEx = '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@!?#$%&-+_‚"=])(?=.*[0-9])\\S*$'

    constructor() {}

    ngOnInit(){
        this.password = this.passwordFormGroup.get('password')! as FormControl;
        this.confirmPassword = this.passwordFormGroup.get('confirmPassword')! as FormControl;
        this.password.setValidators([Validators.required, Validators.pattern( this.regEx)]);
        this.passwordFormGroup.setValidators([confirmPasswordValidator(this.password, this.confirmPassword)])
    }
    getPassErrorMessage(){
        if(this.password.hasError('pattern')){
            return ValidationMessages.PASSWORD_INCOMPLETE
        }
        if(this.password.hasError('required')){
            return ValidationMessages.REQUIRED;
        }
        if(this.password.value != this.confirmPassword.value){
            return ValidationMessages.PASSWORD_FAILURE;
        }
        return null
    }


}