<div *ngIf="addressFormGroup" class="address-group" [formGroup]="addressFormGroup">
    <div style="padding-bottom: 8px; font-size: 16px; font-weight: bold">Adresse</div>
    <div class="address-cluster" [class.compact]="compact">
        <mat-form-field [class.field-compact]="compact" color="accent">
            <mat-icon fontSet="material-icons-outlined" matPrefix>edit_road</mat-icon>
            <mat-label>Strasse</mat-label>
            <input matInput formControlName="street">
            <mat-error>Gib eine Straße an.</mat-error>
        </mat-form-field>
        <mat-form-field [class.field-compact]="compact" [class.pl]="compact" [class.house-number]="compact"
                        color="accent">
            <mat-label>Nummer</mat-label>
            <mat-icon fontSet="material-icons-outlined" matPrefix>home</mat-icon>
            <input matInput formControlName="houseNumber">
            <mat-error>Erforderlich</mat-error>
        </mat-form-field>
    </div>
    <div class="address-cluster" [class.compact]="compact">
        <mat-form-field [class.field-compact]="compact" color="accent">
            <mat-label>Postleitzahl</mat-label>
            <mat-icon fontSet="material-icons-outlined" matPrefix>location_city</mat-icon>
            <input type="number" matInput formControlName="postalCode">
            <mat-error>Gib eine Postleitzahl an.</mat-error>
        </mat-form-field>
        <mat-form-field [class.field-compact]="compact" [class.pl]="compact" color="accent">
            <mat-label>Stadt</mat-label>
            <mat-icon fontSet="material-icons-outlined" matPrefix>location_city</mat-icon>
            <input matInput formControlName="city">
            <mat-error>Gib eine Stadt an.</mat-error>
        </mat-form-field>
    </div>
</div>
