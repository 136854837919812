export class Endpoint{
  public static RESTAURANTS = '/admin/restaurants'
  public static REGISTER_TENANT = '/admin/registration/registerTenant'
  public static REGISTER_RESTAURANT = '/admin/registration/registerRestaurant'
  public static TENANTS = "/admin/tenants"
  public static IMPERSONATE = "/auth/impersonate"
  public static LOGIN = "/adminLogin"
  public static REFRESH = "/public/auth/refresh"
  public  static PAID = '/admin/tenants/payment-cache/'
  public static DELETE_TENANT = 'admin/tenants/'
}
