import {Injectable} from "@angular/core";


const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() {
    }

    signOut() {
        window.sessionStorage.clear()
    }

    public saveToken(token: string) {
        window.sessionStorage.removeItem(TOKEN_KEY)
        window.sessionStorage.setItem(TOKEN_KEY, token)
    }

    public saveRefreshToken(token: string) {
        window.sessionStorage.removeItem(REFRESHTOKEN_KEY)
        window.sessionStorage.setItem(REFRESHTOKEN_KEY, token)
    }

    public getToken(): string | null {
        return window.sessionStorage.getItem(TOKEN_KEY)
    }

    public getRefreshToken(): string | null {
        return window.sessionStorage.getItem(REFRESHTOKEN_KEY)
    }
}
