import {Injectable} from "@angular/core";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import {BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError} from "rxjs";
import {AuthService} from "./auth.service";
import {TokenStorageService} from "./tokenstorage.service";
import {Router} from "@angular/router";
import {Endpoint} from "../../endpoints.definition";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(private tokenService: TokenStorageService, private authService: AuthService, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.tokenService.getToken();
        if (token != null && !req.url.includes(Endpoint.LOGIN) && !req.url.includes(Endpoint.REFRESH)) {
            authReq = this.addTokenHeader(req, token);
        }

        return next.handle(authReq).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && !authReq.url.includes(Endpoint.LOGIN) && error.status === 401) {
                return this.handle401Error(authReq, next);
            }
            return throwError(error);
        }));
    }


    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const token = this.tokenService.getRefreshToken();
            if (token)
                return this.authService.refreshToken(token).pipe(
                    catchError((err) => {
                        //This does not get called, I don't know why
                        this.isRefreshing = false;
                        this.tokenService.signOut();
                        this.router.navigate(['/login'])
                        return throwError(err);
                    }),
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        this.tokenService.saveToken(token.access_token);
                        this.refreshTokenSubject.next(token.refresh_token);

                        return next.handle(this.addTokenHeader(request, token.access_token));
                    })
                );
            else {
                this.isRefreshing = false;
                this.tokenService.signOut();
                this.router.navigate(['/login'])
                console.log("Should navigate to login")

            }
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }


    private addTokenHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)})
    }
}
