<div class="wrapper">
<div style="font-size: 32px; font-weight: bold; padding: 32px 0">Nutzerliste</div>
<div style=" padding-bottom:32px " *ngFor="let tenant of tenantsRestaurants$ | async ; index as index">
  <default-card >
    <div style="display: flex; flex-direction: row; width: 480px; justify-content: space-between">
      <div>
    <div style="display: flex; flex-direction: row;">
    <div style="font-size: 24px; font-weight: bold; padding: 0  16px 16px 0">{{tenant.tenant.firstName}} {{tenant.tenant.lastName}}</div>
    <flav-fab-button style="padding-right: 16px" (click)="editTenant(index)"  iconName="edit"></flav-fab-button>
      <flav-fab-button (click)="deleteTenant(tenant.tenant.id, index)"  iconName="delete"></flav-fab-button>
    </div>
        <div style="display: flex; flex-direction: row">
      <div style=" padding-right: 16px">Email: {{tenant.tenant.email}}</div>
      <div>Telefon: {{tenant.tenant.phoneNumber}}</div>
        </div>
        <div style="display: flex; flex-direction: row">
        <div style=" padding-right: 8px">Adresse: {{tenant.tenant.address.street}}</div>
        <div style=" padding-right: 16px">{{tenant.tenant.address.houseNumber}}</div>
        <div style=" padding-right: 16px">{{tenant.tenant.address.postalCode}}</div>
        <div>{{tenant.tenant.address.city}}</div>
        </div>
       <div style="display: flex; flex-direction: row; padding-top: 8px">
         <div style="line-height: 1.7; padding-right: 16px">Tenant paid:</div>


         <flav-fab-button *ngIf="editPaid != tenant.tenant.id && tenant.tenant.hasPayed" iconName="check" backgrounColor="green" color="white" (click)="changePaid(tenant.tenant.id)"></flav-fab-button>
         <flav-fab-button *ngIf="editPaid != tenant.tenant.id && !tenant.tenant.hasPayed" iconName="close" backgrounColor="red" color="white" (click)="changePaid(tenant.tenant.id)"></flav-fab-button>
         <mat-form-field *ngIf="editPaid == tenant.tenant.id" color="accent" appearance="outline" [formGroup]="getTenantFormGroup(index)">
           <mat-label>Expire Date</mat-label>
           <input  matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="validTo">
           <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
           <mat-datepicker   #picker ></mat-datepicker>
         </mat-form-field>
         <flav-fab-button style="padding: 0 8px;" *ngIf="editPaid == tenant.tenant.id" iconName="save" (click)="savePaid(tenant.tenant, index)"></flav-fab-button>
         <flav-fab-button *ngIf="editPaid == tenant.tenant.id" iconName="close" (click)="abortPaid(index)"></flav-fab-button>
       </div>
        <div style="font-weight: bold; padding: 16px 0 8px 0">Restaurants</div>
  <div *ngFor="let restaurant of tenant.restaurants; index as resIndex">
    <div style="display: flex; flex-direction: row;">
    <div style=" padding: 0 8px 8px 0">{{restaurant.name}}</div>
    <flav-fab-button (click)="editRestaurant(index, resIndex)" iconName="edit"></flav-fab-button>
    </div>
  </div>
    <flav-fab-button (click)="addRestaurant(index, tenant.tenant.id)" iconName="add"></flav-fab-button>
      </div>
    <button style="min-width: 10px;" mat-raised-button color="accent" (click)="loginTenant(tenant.tenant.id)">
      <mat-icon style="padding-left: 10px">arrow_forward</mat-icon>
    </button>
    </div>
  </default-card>
</div>
  <div style="padding-bottom: 32px">
<button mat-fab (click)="addTenant()" >
  <mat-icon>add</mat-icon>
</button>
  </div>
</div>
