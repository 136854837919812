export class ValidationMessages{
  public static SUCCES: string = "Speichern erfolgreich!!!"
  public static FAILURE: string = "Fehler! Bitte die rot markierten Felder ausfüllen"
  public static HTTP_FAILURE: string = "Da ist etwas schief gelaufen :/"
  public static LOGIN_FAILURE: string = "Falscher Benutzername oder Passwort"
  public static OK: string = "OK"
  public static PASSWORD_FAILURE: string = "Passwörter stimmen nicht überein"
  public static PASSWORD_INCOMPLETE: string = "Das Passwort muss 8 Zeichen mit Großbuchstaben, Kleinbuchstaben, Zahl, und ein Sonderzeichen beinhalten."
  public static PASSWORD_CHANGE_SUCCESS: string = "Dein Passwort wurde erfolgreich zurückgesetzt"
  public static WRONG_EMAIL: string= "Bitte eine gültige Email Adresse eingeben"
  public static REQUIRED: string = "*Pflichtfeld"
  public static REGISTRATION_SUCCES = "Die Registrierung war erfolgreich"
  public static REGISTRATION_FAILED = "Die Registrierung ist fehlgeschlagen"
  public static WRONG_EMAIL_SHORT: string= "Email ungültig"
  public static WRONG_PHONE:string = "Telefonnummer ungültig"
  public static GOT_MAIL: string = "Schau in dein Postfach!"
  public static WRONG_TIME: string = "Falsches Zeitformat! Erfordert: HH:MM"
  public static WRONG_DATE_SHORT: string = "Erfordert: dd.mm.yyyy"
  public static WRONG_IMAGE: string = "Bitte ein Bild mit Dateityp ”png”,\"svg\" oder “jpg“ wählen"
  public static MAX_IMAGE_SIZE: string = "Das Bild ist zu groß. Maximale Größe beträgt 10MB"
  public static DELETED: string = 'Löschen erfolgreich!'
}
