import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent{

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,) {
  }
  deleteFormGroup = new FormGroup({delete: new FormControl('')})

delete(){
 this.dialogRef.close(this.deleteFormGroup.get('delete')?.value)
}

abort(){
  this.dialogRef.close('')
}
}
