<div class="login-wrapper">
<img style="width: 300px; height: 200px; object-fit: cover; padding-top: 64px" src="../../assets/OrangeRegular.svg"/>
<default-card >
<div [formGroup]="loginGroup">
    <h1 style="font-weight: bold">Login</h1>
    <div style="display: flex; flex-direction: column; min-width: 328px">
    <mat-form-field color="accent">
        <mat-label>
            Email
        </mat-label>
        <input matInput  formControlName="email">
    </mat-form-field>

    <mat-form-field color="accent">
        <mat-label>
            Passwort
        </mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>

    </div>
    <div style="width: 100%; text-align: end; padding-top: 40px">
    <button mat-raised-button color="accent" (click)="logIn()">Einloggen</button>
    </div>
</div>
</default-card>
</div>
