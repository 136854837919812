import { Component } from '@angular/core';
import {FlavBoardService} from "./flav-board.service";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Paid, Restaurant, Tenant} from "./flav-board.types";
import {firstValueFrom, Observable, ReplaySubject, tap} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {FlavoroDialogComponent} from "./flavoro-dialog/flavoro-dialog.component";
import CountryDatasource from "../country/country.datasource";
import Country from "../country/country.types";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {environment} from "../../environments/environment";
import {DeleteDialogComponent} from "./delete-dialog/delete-dialog.component";



@Component({
  selector: 'flav-board',
  templateUrl: './flav-board.component.html',
  styleUrls: ['./flav-board.component.scss']
})
export class FlavBoardComponent {

  tenantsRestaurantsFormarray: FormArray = new FormArray<any>([]);
  MOBILE_PATTERN = /^[^A-Za-z]+$/

  constructor(private flavboardService: FlavBoardService, public dialog: MatDialog, private snackbar: MatSnackBar) {
  }

  tenantsRestaurants$ = this.flavboardService.getTenants().pipe(tap((tenantsRestaurants) => this.buildTenantRestaurantFormgoup(tenantsRestaurants)))
  _currentTenantsRestaurantsSubject: ReplaySubject<{
    tenant: Tenant,
    restaurants: Restaurant[]
  }[]> = new ReplaySubject(1);
  currentTenantsRestaurants$ = this._currentTenantsRestaurantsSubject.asObservable();
  editPaid!: string;


  buildTenantRestaurantFormgoup(tenantsRestaurants: any) {
    this._currentTenantsRestaurantsSubject.next(tenantsRestaurants);
    for (let tenantRes of tenantsRestaurants) {
      let tenantFormGroup = new FormGroup({
          id: new FormControl(tenantRes.tenant.id ?? ''),
          firstName: new FormControl(tenantRes.tenant.firstName, [Validators.required] ?? ''),
          lastName: new FormControl(tenantRes.tenant.lastName, [Validators.required] ?? ''),
          email: new FormControl(tenantRes.tenant.email, [Validators.required, Validators.email] ?? ''),
          phoneNumber: new FormControl(tenantRes.tenant.phoneNumber, [Validators.pattern(this.MOBILE_PATTERN)] ?? ''),
          password: new FormControl(tenantRes.tenant.password, [Validators.required] ?? ''),
          confirmPassword: new FormControl(tenantRes.tenant.confirmPassword, [Validators.required] ?? ''),
          validTo: new FormControl(tenantRes.tenant.validTo ?? ''),
          hasPaid: new FormControl(tenantRes.tenant.hasPayed),
          address: new FormGroup({
              id: new FormControl(tenantRes.tenant.address?.id),
              street: new FormControl(tenantRes.tenant.address?.street),
              houseNumber: new FormControl(tenantRes.tenant.address?.houseNumber, [Validators.required]),
              city: new FormControl(tenantRes.tenant.address?.city, [Validators.required]),
              postalCode: new FormControl(tenantRes.tenant.address?.postalCode, [Validators.required]),
              country: new FormGroup({
                id: new FormControl(tenantRes.tenant.address?.country?.id),
                name: new FormControl(tenantRes.tenant.address?.country?.name),
                code: new FormControl(tenantRes.tenant.address?.country?.code),
              })
            }
          )
        }
      )

      let restaurantFormGroup: FormGroup = new FormGroup({});
      let restaurantsFormarray: FormArray = new FormArray<any>([]);
      for (let restaurant of tenantRes.restaurants) {
        restaurantFormGroup = new FormGroup({
            id: new FormControl(restaurant.id ?? ''),
            name: new FormControl(restaurant.name ?? ''),
            contactNumber: new FormControl(restaurant.contactNumber, [Validators.pattern(this.MOBILE_PATTERN)] ?? ''),
            mail: new FormControl(restaurant.mail, [Validators.email] ?? ''),
            tenantId: new FormControl(tenantRes.tenant.id ?? ''),
            address: new FormGroup({
                id: new FormControl(restaurant.address.id),
                street: new FormControl(restaurant.address.street),
                houseNumber: new FormControl(restaurant.address.houseNumber),
                city: new FormControl(restaurant.address.city),
                postalCode: new FormControl(restaurant.address.postalCode),
                country: new FormGroup({
                  id: new FormControl(tenantRes.tenant.address.country.id),
                  name: new FormControl(tenantRes.tenant.address.country.name),
                  code: new FormControl(tenantRes.tenant.address.country.code),
                })
              }
            )
          }
        )
        restaurantsFormarray.push(restaurantFormGroup);
      }
      this.tenantsRestaurantsFormarray.push(new FormGroup({
        tenant: tenantFormGroup,
        restaurants: restaurantsFormarray
      }));
    }

  }

  editTenant(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      tenantFormGroup: this.tenantsRestaurantsFormarray.at(index).get('tenant') as FormGroup,
      tenant: true
    }
    const dialogRef = this.dialog.open(FlavoroDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      async (data) => {
        const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
        if (data) {
          const tenant = Tenant.fromJson(data.getRawValue())
          originalDatas[index].tenant = await firstValueFrom(this.flavboardService.updateTenant(tenant));
          originalDatas[index].tenant.validTo = this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('validTo')?.value;
          originalDatas[index].tenant.hasPayed = this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('hasPaid')?.value;
          this._currentTenantsRestaurantsSubject.next(originalDatas);
        } else {
          await this.abortTenant(index)
        }
      }
    )
  }

  async abortTenant(index: number) {
    const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
    const originalData = originalDatas.at(index)?.tenant;
    const tenant = this.tenantsRestaurantsFormarray.at(index).get('tenant') as FormGroup;
    const tenantAddress = tenant.get('address')! as FormGroup
    const tenantCountry = tenantAddress.get('country')! as FormGroup
    tenant.get('id')?.setValue(originalData?.id);
    tenant.get('firstName')?.setValue(originalData?.firstName);
    tenant.get('lastName')?.setValue(originalData?.lastName);
    tenant.get('email')?.setValue(originalData?.email);
    tenant.get('phoneNumber')?.setValue(originalData?.phoneNumber);
    tenant.get('password')?.setValue(originalData?.password);
    tenant.get('confirmPassword')?.setValue(originalData?.confirmPassword);
    tenantAddress.get('id')?.setValue(originalData?.address.id);
    tenantAddress.get('street')?.setValue(originalData?.address.street);
    tenantAddress.get('houseNumber')?.setValue(originalData?.address.houseNumber);
    tenantAddress.get('city')?.setValue(originalData?.address.city);
    tenantAddress.get('postalCode')?.setValue(originalData?.address.postalCode);
    tenantCountry.get('id')?.setValue(originalData?.address.country.id);
    tenantCountry.get('name')?.setValue(originalData?.address.country.name);
    tenantCountry.get('code')?.setValue(originalData?.address.country.code);
  }

  addTenant() {
    const dialogConfig = new MatDialogConfig();
    let tenantFormGroup = new FormGroup({
        id: new FormControl(''),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phoneNumber: new FormControl('', [Validators.pattern(this.MOBILE_PATTERN)]),
        password: new FormControl(''),
        confirmPassword: new FormControl(''),
        validTo: new FormControl(''),
        hasPaid: new FormControl(''),
        address: new FormGroup({
          id: new FormControl(''),
          street: new FormControl('', [Validators.required]),
          houseNumber: new FormControl('', [Validators.required]),
          city: new FormControl('', [Validators.required]),
          postalCode: new FormControl('', [Validators.required]),
          country: new FormGroup({
            id: new FormControl(''),
            name: new FormControl(''),
            code: new FormControl(''),
          })
        })
      }
    )

    dialogConfig.data = {
      tenantFormGroup: tenantFormGroup,
      tenant: true,
      addTenant: true
    }
    const dialogRef = this.dialog.open(FlavoroDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      async (data) => {
        if (data) {
          const tenant = Tenant.fromJson(data.getRawValue())
          const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$)
          const createdTenant = await firstValueFrom(this.flavboardService.createTenant(tenant));
          this.tenantsRestaurantsFormarray.push(new FormGroup({
            tenant: this.buildTenantFormGroup(tenant),
            restaurants: new FormArray([])
          }));
          originalDatas.push({tenant: createdTenant, restaurants: []})
          this._currentTenantsRestaurantsSubject.next(originalDatas);
        }
      }
    )
  }

  addRestaurant(index: number, tenantId: string) {
    console.log(tenantId)
    const dialogConfig = new MatDialogConfig();
    let restaurantFormGroup = this.buildRestaurantFormGroup(undefined, tenantId);

    dialogConfig.data = {
      restaurantFormGroup: restaurantFormGroup,
      addRestaurant: true
    }
    const dialogRef = this.dialog.open(FlavoroDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      async (data) => {
        if (data) {
          const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$)
          const subdomain = data.get('subdomain')?.value.toLowerCase();
          data.removeControl('subdomain');
          const restaurant = Restaurant.fromJson(data.getRawValue());
          try {
            const createdRestaurant = await firstValueFrom(this.flavboardService.createRestaurant(restaurant, subdomain));
            let restaurantsArray = this.tenantsRestaurantsFormarray.at(index).get('restaurants') as FormArray;
            createdRestaurant.tenantId = tenantId;
            restaurantsArray.push(this.buildRestaurantFormGroup(createdRestaurant));
            originalDatas[index].restaurants.push(createdRestaurant);
            this._currentTenantsRestaurantsSubject.next(originalDatas)
          } catch (err) {
            this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK);
          }
          ;
        }
      }
    )
  }

  editRestaurant(index: number, resIndex: number) {
    const dialogConfig = new MatDialogConfig();
    const restaurantArray = this.tenantsRestaurantsFormarray.at(index).get("restaurants") as FormArray
    dialogConfig.data = {
      restaurantFormGroup: restaurantArray.at(resIndex) as FormGroup,
      tenant: false
    }
    const dialogRef = this.dialog.open(FlavoroDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      async (data) => {
        const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
        if (data) {
          const restaurant = Restaurant.fromJson(data.getRawValue())
          originalDatas[index].restaurants[resIndex] = await firstValueFrom(this.flavboardService.updateRestaurant(restaurant));
          this._currentTenantsRestaurantsSubject.next(originalDatas);
        } else {
          await this.abortRestaurant(index, resIndex)
        }
      }
    )
  }

  async abortRestaurant(index: number, resIndex: number) {
    const restaurantArray = this.tenantsRestaurantsFormarray.at(index).get("restaurants") as FormArray
    const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
    const originalData = originalDatas.at(index)?.restaurants[resIndex];
    const restaurant = restaurantArray.at(resIndex) as FormGroup
    const restaurantAddress = restaurant.get('address')! as FormGroup
    const restaurantCountry = restaurantAddress.get('country')! as FormGroup
    restaurant.get('id')?.setValue(originalData?.id);
    restaurant.get('name')?.setValue(originalData?.name);
    restaurant.get('contactNumber')?.setValue(originalData?.contactNumber);
    restaurant.get('mail')?.setValue(originalData?.mail);
    restaurant.get('tenantId')?.setValue(originalData?.tenantId);
    restaurantAddress.get('id')?.setValue(originalData?.address.id);
    restaurantAddress.get('street')?.setValue(originalData?.address.street);
    restaurantAddress.get('houseNumber')?.setValue(originalData?.address.houseNumber);
    restaurantAddress.get('city')?.setValue(originalData?.address.city);
    restaurantAddress.get('postalCode')?.setValue(originalData?.address.postalCode);
    restaurantCountry.get('id')?.setValue(originalData?.address.country.id);
    restaurantCountry.get('name')?.setValue(originalData?.address.country.name);
    restaurantCountry.get('code')?.setValue(originalData?.address.country.code);
  }


  buildTenantFormGroup(tenant: Tenant) {
    return new FormGroup({
        id: new FormControl(tenant.id ?? ''),
        firstName: new FormControl(tenant.firstName, [Validators.required] ?? ''),
        lastName: new FormControl(tenant.lastName, [Validators.required] ?? ''),
        email: new FormControl(tenant.email, [Validators.required, Validators.email] ?? ''),
        phoneNumber: new FormControl(tenant.phoneNumber, [Validators.pattern(this.MOBILE_PATTERN)] ?? ''),
        password: new FormControl(tenant.password, [Validators.required] ?? ''),
        confirmPassword: new FormControl(tenant.confirmPassword, [Validators.required] ?? ''),
        hasPaid: new FormControl(tenant.hasPayed ?? ''),
        validTo: new FormControl(tenant.validTo ?? ''),
        address: new FormGroup({
            id: new FormControl(tenant.address.id),
            street: new FormControl(tenant.address.street),
            houseNumber: new FormControl(tenant.address.houseNumber, [Validators.required]),
            city: new FormControl(tenant.address.city, [Validators.required]),
            postalCode: new FormControl(tenant.address.postalCode, [Validators.required]),
            country: new FormGroup({
              id: new FormControl(tenant.address.country.id),
              name: new FormControl(tenant.address.country.name),
              code: new FormControl(tenant.address.country.code),
            })
          }
        )
      }
    )
  }

  buildRestaurantFormGroup(restaurant?: Restaurant, tenantId?: string) {
    let formGroup: FormGroup<any> = new FormGroup({
        id: new FormControl(restaurant?.id ?? ''),
        name: new FormControl(restaurant?.name, [Validators.required] ?? ''),
        contactNumber: new FormControl(restaurant?.contactNumber, [Validators.pattern(this.MOBILE_PATTERN)] ?? ''),
        mail: new FormControl(restaurant?.mail, [Validators.email] ?? ''),
        tenantId: new FormControl(restaurant?.tenantId ?? tenantId ?? ''),
        address: new FormGroup({
            id: new FormControl(restaurant?.address.id ?? ''),
            street: new FormControl(restaurant?.address.street ?? ''),
            houseNumber: new FormControl(restaurant?.address.houseNumber ?? ''),
            city: new FormControl(restaurant?.address.city ?? ''),
            postalCode: new FormControl(restaurant?.address.postalCode ?? ''),
            country: new FormGroup({
              id: new FormControl(restaurant?.address.country.id ?? ''),
              name: new FormControl(restaurant?.address.country.name ?? ''),
              code: new FormControl(restaurant?.address.country.code ?? ''),
            })
          }
        )
      }
    )
    if (!restaurant) {
      formGroup.addControl('subdomain', new FormControl(''))
    }
    return formGroup;
  }

  loginTenant(tenantId: string) {
    this.flavboardService.impersonate(tenantId).subscribe(
      (res) => {
        console.log(res);
        window.open(environment.ccUrl + '?impersonateToken=' + res.access_token, "_blank");
      }
    );
  }

  changePaid(tenantId: string) {
    this.editPaid = tenantId;
  }

  async savePaid(tenant: Tenant, index: number) {
    console.log(new Date(this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('validTo')?.value))
    const paid = new Paid(tenant.id, new Date(this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('validTo')?.value))
    try {
      await firstValueFrom(this.flavboardService.updatePaid(paid));
      const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
      this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('hasPaid')?.setValue(true)
      originalDatas[index].tenant.validTo = this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('validTo')?.value;
      originalDatas[index].tenant.hasPayed = this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('hasPaid')?.value;
      this._currentTenantsRestaurantsSubject.next(originalDatas);
      this.editPaid = ""
    } catch (err) {
      this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
    }

  }

  async abortPaid(index: number) {
    const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
    const originalData = originalDatas.at(index)?.tenant;
    this.tenantsRestaurantsFormarray.at(index).get('tenant')?.get('validTo')?.setValue(originalData?.validTo)
    this.editPaid = ""
  }

  getTenantFormGroup(index: number) {
    return this.tenantsRestaurantsFormarray.at(index).get('tenant') as FormGroup
  }

  async deleteTenant(tenantId: string, index: number) {
    let dialogRef = this.dialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(
      async (data) => {
        if (data == 'DELETE') {
          try {
            await firstValueFrom(this.flavboardService.deleteTenant(tenantId));
            this.snackbar.open(ValidationMessages.DELETED, ValidationMessages.OK)
            const originalDatas = await firstValueFrom(this.currentTenantsRestaurants$);
            originalDatas.splice(index, 1);
            this._currentTenantsRestaurantsSubject.next(originalDatas);
          } catch (err) {
            this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
          }
        }
      })
  }
}


