import {Injectable} from "@angular/core";
import {
  ImpersonateDatasource, PaidDatasource,
  RestaurantsDatasource, RestaurantsUpdateDatasource,
  TenantsDatasource,
  TenantsRestaurantDatasource,
  TenantsUpdateDatasource
} from "./flav-board.datasource";
import {Paid, Restaurant, Tenant} from "./flav-board.types";
import {of} from "rxjs";

@Injectable()
export class FlavBoardService{
  constructor(private paidDatasource: PaidDatasource, private impersonateDatasource: ImpersonateDatasource, private restaurantsDatasource: RestaurantsDatasource, private restaurantsUpdateDatasource: RestaurantsUpdateDatasource, private tenantsDatasource: TenantsDatasource, private tenantsRestaurantDatasource: TenantsRestaurantDatasource, private tenantsUpdateDatasource: TenantsUpdateDatasource  ) {

  }

  getTenants(){
   return this.tenantsRestaurantDatasource.get();
  }

  createTenant(tenant: Tenant){
    //return of(tenant) ;
    return this.tenantsDatasource.createTenant(tenant);
  }

  updateTenant(tenant: Tenant){
      return this.tenantsUpdateDatasource.updateTenant(tenant);
  }

  createRestaurant(restaurant: Restaurant, subdomain: string){
    return this.restaurantsDatasource.createRestaurant(restaurant, subdomain)
  }

  updateRestaurant(restaurant: Restaurant){
    return this.restaurantsUpdateDatasource.updateRestaurant(restaurant)
  }

  impersonate(tenantId: string){
    return this.impersonateDatasource.impersonate(tenantId)
  }

  updatePaid(paid: Paid){
   return this.paidDatasource.updatePaid(paid)
  }

  deleteTenant(tenantId: string){
   return this.tenantsUpdateDatasource.delete(tenantId);
  }
}
