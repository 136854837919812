import {READDatasource} from "./read-datasource.abstract";
import {map, Observable} from "rxjs";
import {appendPath} from "../util/path.helper";

export abstract class CRUDDatasource<T, C, E> extends READDatasource<T> {


    create(createDTO: C): Observable<T> {
        return this.http.post<T>(this.path, createDTO).pipe(map(this.serializer));
    }

    update(editDTO: E, id: string): Observable<T> {
        return this.http.put<T>(appendPath(this.path, id), editDTO).pipe(map(this.serializer));
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(appendPath(this.path, id));
    }
}
