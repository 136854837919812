import {FlavoroHttpClient} from "../http/http.client";
import {Injectable} from "@angular/core";

@Injectable()
export abstract class AbstractDatasource<T>{
    constructor(protected http: FlavoroHttpClient) {
    }

    abstract readonly serializer: (json: any) => T;
    abstract readonly path: string;

}