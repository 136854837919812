import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FlavBoardModule} from "../features/flav-board/flav-board.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FlavoroHttpClient} from "../shared/http/http.client";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoginModule} from "../features/login/login.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AuthInterceptor} from "../features/auth/auth.interceptor";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlavBoardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginModule,
    MatSnackBarModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    FlavoroHttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
