import { NgModule } from '@angular/core';
import {FlavBoardComponent} from "./flav-board.component";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {FlavBoardService} from "./flav-board.service";
import {
  ImpersonateDatasource, PaidDatasource,
  RestaurantsDatasource, RestaurantsUpdateDatasource,
  TenantsDatasource,
  TenantsRestaurantDatasource, TenantsUpdateDatasource
} from "./flav-board.datasource";
import {MatCardModule} from "@angular/material/card";
import {DefaultCardModule} from "../ui/default-card/default-card.module";
import {FlavFabButtonModule} from "../ui/flav-fab-button/flav-fab-button.module";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from '@angular/material/icon';
import {FlavoroDialogComponent} from "./flavoro-dialog/flavoro-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import AddressModule from "../address/address.module";
import {FlavPasswordFieldModule} from "../ui/flav-password-field/flav-password-field.module";
import {TokenStorageService} from "../auth/tokenstorage.service";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {DeleteDialogComponent} from "./delete-dialog/delete-dialog.component";



@NgModule({
  declarations: [
    FlavBoardComponent,
    FlavoroDialogComponent,
    DeleteDialogComponent
  ],
  imports: [
    NgForOf,
    AsyncPipe,
    MatCardModule,
    DefaultCardModule,
    FlavFabButtonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
    AddressModule,
    FlavPasswordFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    AddressModule
  ],
  providers: [
    FlavBoardService,
    TenantsDatasource,
    TenantsRestaurantDatasource,
    RestaurantsDatasource,
    TenantsUpdateDatasource,
    RestaurantsUpdateDatasource,
    ImpersonateDatasource,
    TokenStorageService,
    PaidDatasource,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'de-Ger' }
  ],
})
export class FlavBoardModule{}
