import {inject, Injectable} from "@angular/core";
import {CRUDDatasource} from "../../shared/datasource/crud-datasource.abstract";
import {Address, Paid, Restaurant, Tenant, TenantsRestaurants} from "./flav-board.types";
import {Endpoint} from "../../endpoints.definition";
import {Observable} from "rxjs";
import {READDatasource} from "../../shared/datasource/read-datasource.abstract";
import {TokenStorageService} from "../auth/tokenstorage.service";
import {environment} from "../../environments/environment";

export class AddressDTO{
  constructor(
    public id: string,
    public street: string,
    public houseNumber: number,
    public city: string,
    public postalCode: number,
    public countryId: string,
  ) {}
  public static fromJson(json:any){
    return new AddressDTO(
      json.id,
      json.street,
      json.houseNumber,
      json.city,
      json.postalCode,
      json.countryId,
    )
  }
}
export class TenantCreateDTO{
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phoneNumber: string,
    public password: string,
    public confirmPassword: string,
    public callbackUrl: string,
    public address: AddressDTO,
  ){}

  public static fromJson(json:any){
    return new TenantCreateDTO(
      json.id,
      json.firstName,
      json.lastName,
      json.email,
      json.phoneNumber,
      json.password,
      json.confirmPassword,
      json.callbackUrl,
      AddressDTO.fromJson(json.address)
    )
  }
}

export class TenantUpdateDTO{
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phoneNumber: string,
    public address: AddressDTO,
  ){}

  public static fromJson(json:any){
    return new TenantUpdateDTO(
      json.id,
      json.firstName,
      json.lastName,
      json.email,
      json.phoneNumber,
      AddressDTO.fromJson(json.address)
    )
  }
}

export class RestaurantDTO{
  constructor(
    public id: string,
    public name: string,
    public contactNumber: string,
    public mail: string,
    public tenantId: string,
    public address: AddressDTO,
  ) {}

  public static fromJson(json:any){
    return new RestaurantDTO(
      json.id,
      json.name,
      json.contactNumber,
      json.mail,
      json.tenantId,
      AddressDTO.fromJson(json.address),
    )
  }
}

export class RestaurantCreateDTO{
  constructor(
    public id: string,
    public name: string,
    public contactNumber: string,
    public mail: string,
    public tenantId: string,
    public subdomain: string,
    public address: AddressDTO,
  ) {}

  public static fromJson(json:any){
    return new RestaurantCreateDTO(
      json.id,
      json.name,
      json.contactNumber,
      json.mail,
      json.tenantId,
      json.subdomain,
      AddressDTO.fromJson(json.address),
    )
  }
}
export class Impersonate{
 constructor(
   public tenant_id: string,
   public user_token: string,
 ) {
 }
  public static fromJson(json:any){
    return new  Impersonate(
      json.tenant_id,
      json.user_token
    );
  }
}

export class ImpersonateResponse{
  constructor(
    public access_token: string,
  ) {
  }
  public static fromJson(json:any){
    return new  ImpersonateResponse(
      json.access_token
    );
  }
}

function addressDTOConverter(address: Address): AddressDTO{
  return new AddressDTO(
    address.id,
    address.street,
    address.houseNumber,
    address.city,
    address.postalCode,
    address.country.id
  )
}

@Injectable()
export class TenantsDatasource extends CRUDDatasource<Tenant, TenantCreateDTO, TenantUpdateDTO>{
  readonly path = Endpoint.REGISTER_TENANT;
  readonly serializer = Tenant.fromJson;

  createTenant(tenant: Tenant): Observable<Tenant>{
    const addressDTO = addressDTOConverter(tenant.address)
    const tenantDTO = new TenantCreateDTO(
      tenant.id,
      tenant.firstName,
      tenant.lastName,
      tenant.email,
      tenant.phoneNumber,
      tenant.password,
      tenant.confirmPassword,
      environment.ccUrl + '/verify-mail',
      addressDTO
    )
    return this.create(tenantDTO);
  }


}

@Injectable()
export class TenantsUpdateDatasource extends CRUDDatasource<Tenant, TenantCreateDTO, TenantUpdateDTO>{
  readonly path = Endpoint.TENANTS;
  readonly serializer = Tenant.fromJson;


  updateTenant(tenant: Tenant): Observable<Tenant>{
    const addressDTO = addressDTOConverter(tenant.address)
    const tenantDTO = new TenantUpdateDTO(
      tenant.id,
      tenant.firstName,
      tenant.lastName,
      tenant.email,
      tenant.phoneNumber,
      addressDTO
    )
    return this.update(tenantDTO, tenantDTO.id)
  }



}

@Injectable()
export class TenantsRestaurantDatasource extends READDatasource<TenantsRestaurants>{
  readonly path = Endpoint.TENANTS;
  readonly serializer = TenantsRestaurants.fromJson;

  get() {
    return this.getAll();
   }
}

@Injectable()
export class RestaurantsDatasource extends CRUDDatasource<Restaurant, RestaurantCreateDTO, RestaurantCreateDTO>{
  readonly path = Endpoint.REGISTER_RESTAURANT;
  readonly serializer = Restaurant.fromJson;

  createRestaurant(restaurant: Restaurant, subdomain: string): Observable<Restaurant>{
    const addressDTO = addressDTOConverter(restaurant.address)
    const restaurantDTO = new RestaurantCreateDTO(
     restaurant.id,
      restaurant.name,
      restaurant.contactNumber,
      restaurant.mail,
      restaurant.tenantId,
      subdomain,
      addressDTO
    )
    return this.create(restaurantDTO);
  }

}

@Injectable()
export class RestaurantsUpdateDatasource extends CRUDDatasource<Restaurant, RestaurantDTO, RestaurantDTO>{
  readonly path = Endpoint.RESTAURANTS;
  readonly serializer = Restaurant.fromJson;


  updateRestaurant(restaurant: Restaurant): Observable<Restaurant>{
    const addressDTO = addressDTOConverter(restaurant.address)
    const restaurantDTO = new RestaurantDTO(
      restaurant.id,
      restaurant.name,
      restaurant.contactNumber,
      restaurant.mail,
      restaurant.tenantId,
      addressDTO
    )
    return this.update(restaurantDTO, restaurantDTO.id);
  }
}

@Injectable()
export class ImpersonateDatasource extends CRUDDatasource<ImpersonateResponse, Impersonate, Impersonate>{
  authService = inject(TokenStorageService);
  readonly path = Endpoint.IMPERSONATE;
  readonly serializer = ImpersonateResponse.fromJson

  impersonate(tenantId: string){

    const impersonate = new Impersonate(tenantId, this.authService.getToken()!)
    return this.create(impersonate);
  }
}

@Injectable()
export class PaidDatasource extends CRUDDatasource<Paid, Paid, Paid>{
  readonly path = Endpoint.PAID;
  readonly serializer = Paid.fromJson

  updatePaid(paid: Paid){
    return this.update(paid, paid.tenantId);
  }
}








