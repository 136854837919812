import {inject, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import {FlavBoardComponent} from "../features/flav-board/flav-board.component";
import {LoginComponent} from "../features/login/login.component";
import {TokenStorageService} from "../features/auth/tokenstorage.service";


const loginGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {

  const authService = inject(TokenStorageService);
  const router = inject(Router);
  if(!authService.getToken())
    return router.parseUrl("/login");
  return true;
}

const routes: Routes = [{
  path: '',
  redirectTo: 'flavboard',
  pathMatch: 'full'
},
  {
    path: 'flavboard',
    component: FlavBoardComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
