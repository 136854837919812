import {Component, Input} from "@angular/core";

@Component(
    {
        selector: 'flav-fab-button',
        templateUrl: './flav-fab-button.component.html',
        styleUrls: ['./flav-fab-button.component.scss']

    }
)

export class FlavFabButtonComponent{
    @Input() iconName!: string
  @Input() backgrounColor!: string
  @Input() color!: string
}
