import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {tap} from "rxjs";
import {TokenStorageService} from "./tokenstorage.service";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {Endpoint} from "../../endpoints.definition";

const AUTH_API = "auth/"

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: FlavoroHttpClient, private tokenStorageService: TokenStorageService) {
    }

    login(credentials: { username: string, password: string }) {
        return this.http.post(Endpoint.LOGIN, {
            username: credentials.username,
            password: credentials.password
        }, httpOptions).pipe(tap((response: any) => {
            this.tokenStorageService.saveToken(response.access_token);
            this.tokenStorageService.saveRefreshToken(response.refresh_token);
        }));
    }

    logout() {
        return this.http.post(AUTH_API + 'signout', {}, httpOptions).pipe(tap((response: any) => {
            this.tokenStorageService.signOut();
        }));
    }

    refreshToken(token: string) {
        return this.http.post(Endpoint.REFRESH, {
            refreshToken: token
        }, httpOptions);
    }

}
