import {NgModule} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {DefaultCardModule} from "../default-card/default-card.module";
import {MatIconModule} from "@angular/material/icon";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {FlavPasswordFieldComponent} from "./flav-password-field.component";

@NgModule({
    declarations: [
        FlavPasswordFieldComponent
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        CommonModule,
        DefaultCardModule,
        MatIconModule,
        MatSnackBarModule
    ],
    exports: [
        FlavPasswordFieldComponent
    ]
})
export class FlavPasswordFieldModule{}