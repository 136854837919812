import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Address} from "./address.type";
import {AddressForm, buildAddressFormGroup} from "./address.form";

/**
 * Use EITHER the [form] Input OR the [formGroup] Input.
 *
 * [formGroup]:
 * If you use the formGroup Input, you must pass in a FormGroup<AddressForm> object.
 * [required] and [initialValue] are ignored.
 *
 * [form]:
 * If you use the form Input, the component will build and append a FormGroup<AddressForm> object to it, called 'address'.
 * [required] and [initialValue] are used to build the FormGroup<AddressForm> object.
 */

@Component({
    selector: 'flav-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
    @Input() form: FormGroup | undefined;
    @Input() formGroup: FormGroup<AddressForm> | undefined;
    @Input() required: boolean = false;
    @Input() initialValue?: Address;
    @Input() compact: boolean = false;

    addressFormGroup?: FormGroup<AddressForm>;

    ngOnInit() {
        this.addressFormGroup = this.formGroup ? this.formGroup : buildAddressFormGroup(this.initialValue, this.required)
        if (this.form && !this.formGroup) {
            this.form.addControl('address', this.addressFormGroup);
        }
    }
}

