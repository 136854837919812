export function appendPath(basePath: string, appendPath: string): string {

    const separator = '/';
    const oneSeperatorAvailable = basePath.endsWith(separator) || appendPath.startsWith(separator);
    const bothSeperatorsAvailable = basePath.endsWith(separator) && appendPath.startsWith(separator);

    if (bothSeperatorsAvailable) {
        return basePath + appendPath.substring(1);
    }

    if (oneSeperatorAvailable) {
        return basePath + appendPath;
    }

    return basePath + ("/") + appendPath;
}
