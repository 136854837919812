import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../auth/auth.service";

@Component(
    {
        selector: 'login',
        templateUrl: './login.component.html',
        styleUrls: ['./login.component.scss']
    }
)
export class LoginComponent {
    hide = true;
    login = true;
    loginGroup = new FormGroup({
        email: new FormControl(""),
        password: new FormControl("")
    })
    constructor(private authService: AuthService, private router: Router, private snackbar: MatSnackBar) {}

    logIn(){
        const username = this.loginGroup.get("email")?.value;
        const password = this.loginGroup.get("password")?.value;
        if(username && password) {
            this.authService.login({username: username, password: password}).subscribe(() => {
                this.router.navigate(["/"]);
            }, (error) => {
                this.snackbar.open("Falscher Benutzername oder Passwort", "OK", {duration: 5000});
            });
        }
    }

    forgotPass(){
        this.router.navigate(["/password-reset"])
    }
}
