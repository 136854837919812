import {Address} from "./address.type";
import {FormControl, FormGroup, Validators} from "@angular/forms";

type AddressForm = {
    id: FormControl<string | null>,
    street: FormControl<string | null>,
    houseNumber: FormControl<string | null>,
    city: FormControl<string | null>,
    postalCode: FormControl<string | null>,
    countryId: FormControl<string | null>,
}

const buildAddressFormGroup = (initialValue: Address | null | undefined, required = false) => {
    return new FormGroup<AddressForm>({
        id: new FormControl<string>(initialValue?.id ?? ''),
        street: new FormControl<string>(initialValue?.street ?? '', required ? [Validators.required] : []),
        houseNumber: new FormControl<string>(initialValue?.houseNumber ?? '', required ? [Validators.required] : []),
        city: new FormControl<string>(initialValue?.city ?? '', required ? [Validators.required] : []),
        postalCode: new FormControl<string>(initialValue?.postalCode ?? '', required ? [Validators.required] : []),
        countryId: new FormControl(initialValue?.country?.id ?? ""),
    });
}

export {buildAddressFormGroup, AddressForm}
