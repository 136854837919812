import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from "@angular/forms";


export function confirmPasswordValidator(password: FormControl, confirmPassword: FormControl): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
        if(password.hasError('pattern') || password.hasError('required') || confirmPassword.hasError('pattern') || confirmPassword.hasError('required')){
            return {notSame: true}
        }
        if (password?.value == confirmPassword?.value){
            password.setErrors(null)
            confirmPassword.setErrors(null)
            return null
        }
        password.setErrors({notSame: true})
        confirmPassword.setErrors({notSame: true})
        return {notSame: true}
    }
}